export function formatDate(input: string | number | Date, options?: Intl.DateTimeFormatOptions): string {
  if (!options) {
    options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
  }
  return new Date(input).toLocaleDateString('nb-NO', {
    ...options,
  })
}
